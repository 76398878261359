import React, {useCallback, useEffect, useState} from 'react';
import {ProgressBar} from 'react-bootstrap';

interface Countdown {
  secondsLeft: number;
  timeout: boolean;
}

interface Props extends Countdown {
  secondsToCountdownFrom: number;
  showVisualWarning: boolean;
}

// Cannot use a regular string type
type ProgressBarVariantTypes = 'info' | 'warning' | 'danger' | 'success' | undefined;

const HoldemTimer = ({secondsToCountdownFrom, showVisualWarning, secondsLeft, timeout = false }: Props) => {

  const [time, setTime] = useState<Countdown>({secondsLeft: secondsLeft, timeout});
  const [timerColor, setTimerColor] = useState<ProgressBarVariantTypes>('info');

  const tick = useCallback(() => {
    if (time.secondsLeft > 0) {
      setTime({secondsLeft: time.secondsLeft - 1, timeout: false});
    } else if (time.secondsLeft === 0 && !timeout) {
      setTime({secondsLeft: time.secondsLeft, timeout: true});
    }
  }, [time, timeout]);

  useEffect(() => {
    if (showVisualWarning) {
      if (time.secondsLeft > secondsToCountdownFrom / 2) {
        setTimerColor('info');
      } else if (time.secondsLeft > secondsToCountdownFrom / 4) {
        setTimerColor('warning');
      } else {
        setTimerColor('danger');
      }
    }
    const timer = setInterval(() => tick(), 1000);
    return () => clearInterval(timer);
  }, [showVisualWarning, secondsToCountdownFrom, tick, time.secondsLeft]);

  return (
    <div style={{width: '100%', height: '1rem'}}>
      <ProgressBar now={time.secondsLeft} max={secondsToCountdownFrom} label={`${time.secondsLeft.toString().padStart(2, '0')}`} variant={timerColor}/>
    </div>
);
};

export default HoldemTimer;
