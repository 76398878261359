export interface PlayerAnimationProps {
  seat: number;
  left: string;
  bottom: string;
  width: number;
  height: number;
  transform: string;
}

export const chipPositions = {
  topRow: '65%',
  centerRow: '50%',
  bottomRow: '35%',
  leftColumn: '20%',
  leftCenterColumn: '30%',
  centerColumn: '50%',
  rightCenterColumn: '70%',
  rightColumn: '80%'
} as const;

export const playerPositions = {
  topRow: '85%',
  centerRow: '50%',
  bottomRow: '15%',
  leftColumn: '10%',
  leftCenterColumn: '30%',
  centerColumn: '50%',
  rightCenterColumn: '70%',
  rightColumn: '90%'
} as const;

export const calculateSeatPosition = (seat: number, relativeSeat: number, width: number, height: number): PlayerAnimationProps => {
  let left = '';
  let bottom = '';
  if (relativeSeat === 1 || relativeSeat === 5) {
    left = playerPositions.centerColumn;
    relativeSeat === 1 ? bottom = playerPositions.bottomRow : bottom = playerPositions.topRow;
  } else if (relativeSeat === 2 || relativeSeat === 4) {
    left = playerPositions.leftCenterColumn;
    relativeSeat === 2 ? bottom = playerPositions.bottomRow : bottom = playerPositions.topRow;
  } else if (relativeSeat === 3 || relativeSeat === 7) {
    relativeSeat === 3 ? left = playerPositions.leftColumn : left = playerPositions.rightColumn;
    bottom = playerPositions.centerRow;
  } else if (relativeSeat === 6 || relativeSeat === 8) {
    left = playerPositions.rightCenterColumn;
    relativeSeat === 8 ? bottom = playerPositions.bottomRow : bottom = playerPositions.topRow;
  }
  return {seat: seat, left: left, bottom: bottom, width: width, height: height, transform: 'translate(-50%, 50%'};
};
