import * as React from 'react';
import {Switch, Route, Router} from 'react-router-dom';
import History from './history';
import NotFound from '../pages/NotFound/NotFound';
import {PrivateRoute} from '../components/util/PrivateRoute/PrivateRoute';
import Login from '../pages/Login/Login';
import {RoutePaths} from './RoutePaths';
import {LogOut} from '../pages/Logout/Logout';
import {HomeRedirect} from '../pages/Home/HomeRedirect/HomeRedirect';
import Holdem from '../pages/Holdem/Holdem';
import ForgotPassword from '../pages/ForgotPassword/ForgotPassword';
import ResetPassword from '../pages/ResetPassword/ResetPassword';
import {PrivateRouteWithWebsocket} from '../components/util/PrivateRouteWithWebSocket/PrivateRouteWithWebsocket';
import {PublicRouteWithWebsocket} from '../components/util/PublicRouteWithWebSocket/PublicRouteWithWebsocket';
import HoldemGame from '../pages/Holdem/pages/HoldemGame/HoldemGame';
import Home from '../pages/Home/Home';
import Register from '../pages/Register/Register';

const Routes = () => (
  <Router history={History}>
    <Switch>
      <Route path={RoutePaths.login} component={Login} redirectTo={RoutePaths.Home}/>
      <Route path={RoutePaths.logout} component={LogOut} redirectTo={RoutePaths.login}/>
      <Route path={RoutePaths.register} component={Register} redirectTo={RoutePaths.login}/>
      <Route path={RoutePaths.forgotPassword} component={ForgotPassword} redirectTo={RoutePaths.Home}/>
      <Route path={RoutePaths.resetPassword} component={ResetPassword} redirectTo={RoutePaths.Home}/>
      <PublicRouteWithWebsocket exact={true} path={RoutePaths.holdemGamePaths.Spectate} component={HoldemGame} renderPageHeader={true}/>
      <PrivateRouteWithWebsocket path={[RoutePaths.holdemGamePaths.Play, RoutePaths.holdemGamePaths.Spectate]} component={Holdem} renderPageHeader={true}/>
      <PrivateRoute path={[RoutePaths.holdemPaths.Home, RoutePaths.about, RoutePaths.reportBug, RoutePaths.account]} component={Home}/>
      <PrivateRoute path={['/']} component={HomeRedirect}/>
      <Route component={NotFound}/>
    </Switch>
  </Router>
);

export default Routes;
