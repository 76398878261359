import {Button, Form} from 'react-bootstrap/es';
import {EPokerAction} from '../../../common/redux/holdem/games';
import React, {FormEvent, useState} from 'react';
import {FormControlProps} from 'react-bootstrap/FormControl';
import {bindActionCreators, Dispatch} from 'redux';
import {holdemSocketStore} from '../../../common/redux/holdem/holdemSocket';
import {connect} from 'react-redux';
import {useMount} from '../../../hooks/useMount';

// Originally used a discriminated union type to require correct props for dispatch action based on type, but it does not work with redux connect
interface FormProps {
  buttonLabel: string;
  type: 'pokerAction' | 'holdemChatMessage';
  tableId?: string;
  action?: EPokerAction;
}

type Props = FormProps & ReturnType<typeof mapDispatchToProps>;

// A common component for action forms to keep parent from re-rendering when typing in the form
const DispatchActionForm = (props: Props) => {
  const {buttonLabel, type, tableId, action, actions: {sendPokerAction, sendChatMessage}} = props;

  const [currentValue, setCurrentValue] = useState('');
  const [formType, setFormType] = useState<'string' | 'number'>('number');
  useMount(() => {
    switch (type) {
      case 'pokerAction':
        setFormType('number');
        break;
      case 'holdemChatMessage':
        setFormType('string');
        break;
    }
  });

  const sendCorrectAction = () => {
    // May need a default case
    switch (type) {
      case 'pokerAction':
        if (action && tableId) {
          sendPokerAction(tableId, action, currentValue);
        }
        break;
      case 'holdemChatMessage':
        if (tableId) {
          sendChatMessage(tableId, currentValue);
        }
        break;
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    sendCorrectAction();
  };

  return (
    <div style={{display: 'flex', flexDirection: 'row', flex: '1', justifyContent: 'space-between'}}>
      <Form style={{display: 'flex', flex: '1'}} onSubmit={(e: React.FormEvent<HTMLFormElement>) => handleSubmit(e)}>
        <Button type={'submit'}>
          {buttonLabel}
        </Button>
        <Form.Control
          type={formType}
          value={currentValue.toString()}
          onChange={(event: FormEvent<FormControlProps>) => {
            setCurrentValue(event.currentTarget.value!.toString());
          }}
        />
      </Form>
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    sendPokerAction: holdemSocketStore.actions.sendPokerAction,
    sendChatMessage: holdemSocketStore.actions.sendChatMessage
  }, dispatch)});

export default connect(null, mapDispatchToProps)(DispatchActionForm);
