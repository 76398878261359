import React, {useMemo, useState} from 'react';
import osrsGoldCoin from '../../../../../assets/images/osrsGoldCoin.png';
import osrsGoldStack from '../../../../../assets/images/osrsGoldStack.png';
import osrsGoldPile from '../../../../../assets/images/osrsGoldPile.png';

interface Props {
  currentWager: number;
  bigBlindAmount: number;
  maxHeight: number;
  maxWidth: number;
  displayText: boolean;
}

interface Picture {
  Url: string;
  Height: string;
  Width: string;
}

const RenderChips = (props: Props) => {
  const {currentWager, bigBlindAmount, maxHeight, maxWidth, displayText} = props;

  const [picture, setPicture] = useState<Picture>();
  const [currentWagerText, setCurrentWagerText] = useState('');

  useMemo(() => {
      if (currentWager > 0) {
        if (currentWager > bigBlindAmount * 4) {
          setPicture({
            Url: osrsGoldPile,
            Height: `${maxHeight}px`,
            Width: `${maxWidth}px`});
        } else if (currentWager > bigBlindAmount * 2) {
          setPicture({
            Url: osrsGoldStack,
            Height: `${maxHeight / 2}px`,
            Width: `${maxWidth / 2}px`});
        } else {
          setPicture({
            Url: osrsGoldCoin,
            Height: `${maxHeight / 4}px`,
            Width: `${maxWidth / 4}px`});
        }
        if (currentWager > 0 && displayText) {
          setCurrentWagerText(`Wager: ${currentWager}`);
        } else {
          setCurrentWagerText('');
        }
      }
    }, [currentWager, bigBlindAmount, displayText, maxHeight, maxWidth]
  );

  return (
    <div>
      {(picture && currentWager > 0) ? (
        <>
            <img
              src={picture.Url}
              style={{maxHeight: picture.Height, maxWidth: picture.Width}}
              alt={currentWager.toString()}
            />
          <div>{currentWagerText}</div>
        </>
      ) : null
      }
    </div>
  );
};

export default RenderChips;
