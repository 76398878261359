import * as React from 'react';
import {match} from 'react-router-dom';
import {convertComponentPaths, RoutePath, RoutePaths} from '../../router/RoutePaths';
import {MultipleRouteSwitchRenderer} from '../../router/RouteRenderer';
import HoldemLobby from '../Holdem/pages/HoldemLobby/HoldemLobby';
import HoldemHome from '../Holdem/pages/HoldemHome/HoldemHome';
import About from './pages/About/About';
import ReportBug from './pages/ReportBug/ReportBug';
import AccountInformation from './pages/Account/AccountInformation';

interface Props {
  match: match;
}

const homeRoutes: RoutePath[] = [
  ...convertComponentPaths(
    {component: HoldemHome, paths: [RoutePaths.holdemPaths.Home]},
    {component: About, paths: [RoutePaths.about]},
    {component: ReportBug, paths: [RoutePaths.reportBug]},
  {component: AccountInformation, paths: [RoutePaths.account]}
  )
];

const privateWebsocketRoutes: RoutePath[] = [
  ...convertComponentPaths(
    {component: HoldemLobby, paths: [RoutePaths.holdemPaths.Lobby]}
  )
];

export const HomeRoutes = (props: Props) => (
    <MultipleRouteSwitchRenderer routePaths={homeRoutes} privateRouteWithWebsocketPaths={privateWebsocketRoutes}/>
);
