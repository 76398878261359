import React from 'react';
import {animated, config, useSprings} from '@react-spring/web';
import {Pot} from '../../../../../../../common/redux/holdem/games';
import RenderChips from '../../RenderChips';
import styles from './AnimatedPots.module.scss';

interface Props {
  tablePots: Pot[];
  bigBlindAmount: number;
  chipWidth: number;
  chipHeight: number;
}

const AnimatedPots = (props: Props) => {
  const {tablePots, bigBlindAmount, chipWidth, chipHeight} = props;

  const [potSprings] = useSprings(tablePots.length, i => ({
    from: {transform: 'translate(20%, 10%)', opacity: 0},
    to: {transform: `translate(0%, 0%)`, opacity: 1},
    delay: i * 150,
    config: config.molasses
  }));

  return (
    <>
      <div className={styles['pot-container']}>
        {potSprings.map(({transform, opacity}, i) => (
          <animated.div
            className={styles['pot']}
            style={{transform, opacity}}
            key={i}
          >
            <div/>
            <div>Pot: {tablePots[i].amount}</div>
            <RenderChips
              currentWager={tablePots[i].amount}
              bigBlindAmount={bigBlindAmount}
              maxHeight={chipHeight}
              maxWidth={chipWidth}
              displayText={false}
            />
          </animated.div>
        ))}
      </div>
    </>
  );
};

export default AnimatedPots;
