import React from 'react';
import styles from './ChatBox.module.scss';
import {HoldemChatMessage} from '../../../../../common/redux/holdem/games';
import uuid from 'uuid';
import DispatchActionForm from '../../../../../components/layout/DispatchActionForm/DispatchActionForm';

interface Props {
  tableId?: string;
  currentUsername: string;
  chatMessages: HoldemChatMessage[];
}

const ChatBox = (props: Props) => {
  const {tableId, currentUsername, chatMessages} = props;

  const renderChatMessages = () => {
    return chatMessages.map(chatMessage => {
      const key = `${chatMessage.sender}-${uuid.v4().toString()}`;
      if (chatMessage.sender === currentUsername) {
        return (
          <div key={key} className={styles['player-message']} style={{color: 'gold'}}>
            {chatMessage.sender}: {chatMessage.message}
          </div>
        );
      }
      return (
        <div key={key} className={styles['player-message']}>
          {chatMessage.sender}: {chatMessage.message}
        </div>
      );
    });
  };

  const renderChatMessagingActions = () => {
    if (tableId) {
      return (
        <div className={styles['button-with-form-container']}>
          <DispatchActionForm
            buttonLabel={'Send'}
            type={'holdemChatMessage'}
            tableId={tableId}
          />
        </div>
      );
    }
  };

  return (
    <>
      <div className={styles['chat-box-with-actions-container']}>
        <div className={styles['chat-box']}>
          {chatMessages ? renderChatMessages() : null}
        </div>
        {renderChatMessagingActions()}
      </div>
    </>
  );
};

export default ChatBox;
