import React from 'react';
import {connect} from 'react-redux';

function ReportBug() {
  return (
    <>
      <div>Please report bug now or die</div>
    </>
  );
}

const mapDispatchToProps = () => ({});
const mapStateToProps = () => ({});
export default connect(mapStateToProps, mapDispatchToProps)(ReportBug);
