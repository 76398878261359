import {RoutePath} from './RoutePaths';
import * as React from 'react';
import {Route} from 'react-router';
import {PrivateRouteWithWebsocket} from '../components/util/PrivateRouteWithWebSocket/PrivateRouteWithWebsocket';
import {PublicRouteWithWebsocket} from '../components/util/PublicRouteWithWebSocket/PublicRouteWithWebsocket';
import {Switch} from 'react-router-dom';
import {PrivateRoute} from '../components/util/PrivateRoute/PrivateRoute';
import NotFound from '../pages/NotFound/NotFound';

export const RouteRenderer = (props: {routePaths: RoutePath[]}) => (
  <>
    {props.routePaths.map(r => (<Route exact={true} path={r.path} key={r.path} component={r.component}/>))}
  </>
);

interface MultipleRouteProps {
  routePaths?: RoutePath[];
  privateRoutePaths?: RoutePath[];
  privateRouteWithWebsocketPaths?: RoutePath[];
  publicRouteWithWebsocketPaths?: RoutePath[];
}

const GetRoutes = (props: {routePaths: RoutePath[]}) => {
  return props.routePaths.map(r => (<Route exact={true} path={r.path} key={r.path} component={r.component}/>));
};

const GetPrivateRoutes = (props: {routePaths: RoutePath[]}) => {
  return props.routePaths.map(r => (<PrivateRoute exact={true} path={r.path} key={r.path} component={r.component}/>));
};

const GetPrivateRoutesWithWebsockets = (props: {routePaths: RoutePath[]}) => {
    return props.routePaths.map(r => (<PrivateRouteWithWebsocket exact={true} path={r.path} key={r.path} component={r.component} renderPageHeader={false}/>));
};

const GetPublicRoutesWithWebsockets = (props: {routePaths: RoutePath[]}) => {
  return props.routePaths.map(r => (<PublicRouteWithWebsocket exact={true} path={r.path} key={r.path} component={r.component} renderPageHeader={false}/>));
};

/*Used since switches do not support multiple react fragments*/
export const MultipleRouteSwitchRenderer = (props: MultipleRouteProps) => (
  <Switch>
    {props.routePaths ? GetRoutes({routePaths: props.routePaths}) : null}
    {props.privateRoutePaths ? GetPrivateRoutes({routePaths: props.privateRoutePaths}) : null}
    {props.privateRouteWithWebsocketPaths ? GetPrivateRoutesWithWebsockets({routePaths: props.privateRouteWithWebsocketPaths}) : null}
    {props.publicRouteWithWebsocketPaths ? GetPublicRoutesWithWebsockets({routePaths: props.publicRouteWithWebsocketPaths}) : null}
    <Route component={NotFound}/>
  </Switch>
);
