import React, {useState} from 'react';
import {WebState} from '../../../../redux/types/WebState';
import {connect} from 'react-redux';
import {RouteComponentProps} from 'react-router';
import {bindActionCreators, Dispatch} from 'redux';
import {useMount} from '../../../../hooks/useMount';
import {bankStore} from '../../../../common/redux/entities/bank';
import {addBalanceToBank, getBank, removeBalanceFromBank} from '../../../../api/bankApi';
import {userStore} from '../../../../common/redux/entities/user';
import {Button, ListGroup} from 'react-bootstrap';

type Props = RouteComponentProps & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

function HoldemLobby({actions: {setCurrentBank}, currentUser, currentBank}: Props) {

  const [buttonsDisabled, setButtonsDisabled] = useState(true);

  const getAndSetBank = async (id: number) => {
    const bank = await getBank(`${id}`);
    if (bank) {
      setCurrentBank(bank);
    }
  };

  useMount(async () => {
    if (currentUser) {
      await getAndSetBank(currentUser.id);
      setButtonsDisabled(false);
    }
  });

  const handleRemoveBalance = async () => {
    setButtonsDisabled(true);
    if (currentBank && currentUser) {
      await removeBalanceFromBank(`${currentUser.id}`, currentBank.balance);
      await getAndSetBank(currentUser.id);
    }
    setButtonsDisabled(false);
  };

  const handleAddBalance = async () => {
    setButtonsDisabled(true);
    if (currentBank && currentUser) {
      const amount = 1000000 - currentBank.balance;
      if (amount > 0) {
        await addBalanceToBank(`${currentUser.id}`, amount);
        await getAndSetBank(currentUser.id);
      }
    }
    setButtonsDisabled(false);
  };

  return (
    <>
      <ListGroup>
        <ListGroup.Item>
          UserName: {currentUser?.username}
        </ListGroup.Item>
        <ListGroup.Item>
          Email address: {currentUser?.email}
        </ListGroup.Item>
        <ListGroup.Item>
          Role: {currentUser?.roleName}
        </ListGroup.Item>
        <ListGroup.Item>
          Balance: {currentBank?.balance}
        </ListGroup.Item>
      </ListGroup>
      <Button variant={'primary'} onClick={async () => !buttonsDisabled ? await handleAddBalance() : null}>
        Top up balance
      </Button>
      <Button variant={'primary'} onClick={async () => !buttonsDisabled ? await handleRemoveBalance() : null}>
        Remove all balance
      </Button>
    </>
  );
}

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    setCurrentBank: bankStore.actions.setCurrentBank
  }, dispatch)});
const mapStateToProps = (state: WebState) => ({
  currentUser: userStore.selectors.getCurrentUser(state),
  currentBank: bankStore.selectors.getCurrentBank(state)
});
export default connect(mapStateToProps, mapDispatchToProps)(HoldemLobby);
