import React, {FormEvent} from 'react';
import styles from './Register.module.scss';
import {Alert, Form, Image} from 'react-bootstrap';
import {Redirect} from 'react-router-dom';
import {
  BootstrapFormEvent
} from '../../util';
import {isAuthenticated, RegisterRequest, userStore, UserWithToken} from '../../common/redux/entities/user';
import {bindActionCreators, Dispatch} from 'redux';
import {connect} from 'react-redux';
import {RoutePaths} from '../../router/RoutePaths';
import {AxiosResponse} from 'axios';
import {WebState} from '../../redux/types/WebState';
import {LoadingButton} from '../../components/util/widgets/LoadingButton/LoadingButton';
import {
  ErrorResponse,
  getErrorResponseMessage,
  getResponseData, isBadRequest,
  isServerError,
  isUnknownError
} from '../../common/util/http';
import {getLogoLink} from '../../appTheme';
import {register} from '../../api/authApi';

export type RegisterErrorResponse = ErrorResponse<RegisterRequest>;

interface RegisterPageState {
  redirectUrl: string;
  errorMessage: string;
  submitted: boolean;
  emailTouched: boolean;
  submitting: boolean;
  form: RegisterRequest;
  errors?: RegisterErrorResponse;
}

type RegisterProps = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

class Register extends React.Component<RegisterProps, RegisterPageState> {
  state: RegisterPageState = {
    redirectUrl: '',
    errorMessage: '',
    submitted: false,
    submitting: false,
    emailTouched: false,
    form: {
      email: '',
      username: '',
      password: '',
      confirmPassword: ''
    }
  };

  componentDidMount(): void {
    if (this.props.authenticated) {
      this.setState({redirectUrl: RoutePaths.Home});
    }
  }

  onInputChange = (event: BootstrapFormEvent) => {
    const input: HTMLInputElement = event.currentTarget as unknown as HTMLInputElement;
    if (input.name === 'email') {
      this.setState({emailTouched: true});
    }
    this.setState({form: {...this.state.form, [input.name]: event.currentTarget.value}});
  };

  async makeRequest(): Promise<AxiosResponse<UserWithToken>> {
      return await register(this.state.form);
  }

  onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    this.setState({submitted: true});
    this.setState({errorMessage: ''});
    if (this.state.form.email.length === 0) {
      this.setState({errorMessage: 'You must enter an email address or username.'});
      return;
    }
    if (this.state.form.password !== this.state.form.confirmPassword) {
      this.setState({errorMessage: 'Passwords must match'});
      return;
    }

    await this.trySubmit();
  };

  async trySubmit() {
    try {
      this.setState({submitting: true});
      const resp = await this.makeRequest();
      this.props.actions.setCurrentUser(resp.data);
      this.setState({redirectUrl: RoutePaths.Home});
    } catch (e) {
      if (isBadRequest(e)) {
        this.setState({errorMessage: getErrorResponseMessage(e), submitting: false, errors: (getResponseData(e) as any).errors});
      } else if (isUnknownError(e) || isServerError(e)) {
        this.setState({errorMessage: isUnknownError(e) ? 'Could not make a connection!' : 'A server error has occurred', submitting: false});
      }
    }
  }

  renderRedirect() {
    if (this.state.redirectUrl.length !== 0) {
      return <Redirect to={this.state.redirectUrl} />;
    }
    return null;
  }

  render() {
    return (
    <div className={styles['register-page']}>
      {this.renderRedirect()}
      <div className={styles['logo']}>
        <Image alt='CsgoHoldem Logo' src={getLogoLink()}/>
      </div>
      <div className={styles['register-form']}>
        <Form onSubmit={this.onSubmit}>
          <Form.Row>
            <Form.Control
              required={true}
              type='text'
              name='email'
              placeholder='Email'
              onChange={this.onInputChange}
              value={this.state.form.email}
            />
          </Form.Row>
          <br/>
          <Form.Row>
            <Form.Control
              required={true}
              type='text'
              name='username'
              placeholder='Username'
              onChange={this.onInputChange}
              value={this.state.form.username}
            />
          </Form.Row>
          <br/>
          <Form.Row>
            <Form.Control
              required={true}
              type='password'
              name='password'
              placeholder='Password'
              onChange={this.onInputChange}
              value={this.state.form.password}
            />
          </Form.Row>
          <br/>
          <Form.Row>
            <Form.Control
              required={true}
              type='password'
              name='confirmPassword'
              placeholder='Confirm Password'
              onChange={this.onInputChange}
              value={this.state.form.confirmPassword}
            />
          </Form.Row>
          <Form.Row className={styles['register-button']}>
            <LoadingButton type='submit' loading={this.state.submitting} label='Submit'/>
          </Form.Row>
          {this.state.errorMessage ?
            <Alert style={{marginTop: '1rem'}} variant='danger'>{this.state.errorMessage}</Alert> : null}
          {this.state.errors ?
            <div style={{display: 'flex'}}>
              {this.state.errors.email !== undefined ?
                <Alert style={{marginTop: '1rem'}} variant='danger'>{this.state.errors.email[0]}</Alert> : null}
              {this.state.errors.password !== undefined ?
                <Alert style={{marginTop: '1rem'}} variant='danger'>{this.state.errors.password[0]}</Alert> : null}
            </div>
             : null
          }
        </Form>
      </div>
    </div>
    );
  }
}
const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({setCurrentUser: userStore.actions.setCurrentUser}, dispatch)});
const mapStateToProps = (state: WebState) => ({ authenticated: isAuthenticated(state)});

export default connect(mapStateToProps, mapDispatchToProps)(Register);
