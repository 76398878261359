import React, {useCallback, useEffect} from 'react';
import styles from './HoldemGame.module.scss';
import IconButton from '../../../../components/util/widgets/IconButton/IconButton';
import {RoutePaths} from '../../../../router/RoutePaths';
import {bindActionCreators, Dispatch} from 'redux';
import {holdemSocketStore} from '../../../../common/redux/holdem/holdemSocket';
import {WebState} from '../../../../redux/types/WebState';
import {connect} from 'react-redux';
import {useMount} from '../../../../hooks/useMount';
import {RouteComponentProps} from 'react-router';
import {Button} from 'react-bootstrap/es';
import {
  EPokerAction,
  Game,
  gamesStore,
  HoldemPlayer
} from '../../../../common/redux/holdem/games';
import {userStore} from '../../../../common/redux/entities/user';
import pokerTable from '../../../../assets/images/pokerTable.png';
// import dealerButton from '../../../../assets/images/dealer.png';
import HoldemTimer from './components/HoldemTimer';
import AnimatedHoldemGame from './components/animatedComponents/AnimatedHoldemGame';
import HoldemChatBox from '../components/ChatBox/HoldemChatBox/HoldemChatBox';
import {Container} from 'react-bootstrap';
import HoldemGameAudioController from './components/audio/HoldemGameAudioController';
import DispatchActionForm from '../../../../components/layout/DispatchActionForm/DispatchActionForm';

type Props = RouteComponentProps<{tableId: string}> & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

function HoldemGame(props: Props) {
  // tslint:disable-next-line:max-line-length
  const {currentGame, getOldPlayerId, currentUser, userToken, actions: {joinTable, leaveTable, sendPokerAction}, match: {params: {tableId}}} = props;
  useMount(async () => {
    const oldId = getOldPlayerId(tableId);
    if (!currentGame && oldId) {
      await joinTable(tableId, oldId);
    } else {
      await joinTable(tableId, userToken);
    }
  });

  useEffect(() => {
    function exit() {
      leaveTable();
    }
    return () => exit();
  }, [tableId, leaveTable]);

  const getActivePlayerId = useCallback(() => {
    if (currentGame) {
      const player = currentGame.holdemPlayers.find((p: HoldemPlayer) => p.seat === currentGame.activePlayerSeat);
      if (player) {
        return player.id;
      }
    }
    return '';
  }, [currentGame]);

  const currentUserIsActive = useCallback(() =>
    // tslint flags currentUser && currentUser.id.toString() === getActivePlayerId() ? true : false to be simplified to this
    !!(currentUser && currentUser.id.toString() === getActivePlayerId()), [currentUser, getActivePlayerId]
  );

  const renderActions = () => {
    return currentGame?.currentPlayer?.pokerActions.map((action: EPokerAction) => {
      if (EPokerAction[action] === 'Raise' || EPokerAction[action] === 'Bet') {
        return (
          <div key={action}>
            <DispatchActionForm
              buttonLabel={EPokerAction[action]}
              type={'pokerAction'}
              tableId={tableId}
              action={action}
            />
          </div>
        );
      }
      if (EPokerAction[action] === 'Call') {
        return (
          <div key={action} style={{marginLeft: '1rem', marginRight: '1rem'}}>
            <Button onClick={() => sendPokerAction(tableId, action, '')}>
              {EPokerAction[action]}</Button>
          </div>
        );
      }
      return (
        <div key={action} style={{marginLeft: '1rem', marginRight: '1rem'}}>
          <Button onClick={() => sendPokerAction(tableId, action, '')}>{EPokerAction[action]}</Button>
        </div>
      );
    });
  };

  const renderAnimatedComponents = (game: Game) => (
    <>
      <AnimatedHoldemGame
        tableId={tableId}
        currentUser={currentUser}
        game={game}
      />
    </>
  );

  const renderOtherInformation = (g: Game, userId: string) => (
    <>
      <div className={styles['chat']}>
        <HoldemChatBox
          tableId={tableId}
          currentUsername={userId}
          playerChat={g.holdemChat}
          dealerChats={g.holdemDealerChatByHand}
        />
      </div>
      <div className={styles['actions']}>
          <div className={styles['flex-row']} style={{justifyContent: 'center'}}>
            {renderActions()}
          </div>
          <div className={styles['flex-row']} style={{justifyContent: 'center'}}>
            <div style={{width: '50%'}}>
              {g.startHandTimer > 0 ?
                <HoldemTimer secondsToCountdownFrom={15} showVisualWarning={false} secondsLeft={g.startHandTimer} timeout={false}/>
                : null}
            </div>
          </div>
      </div>
      <div className={styles['information']}>
        <label>Spectators: {g.holdemPlayers.filter((p: HoldemPlayer) => p.seat === 0).length}</label>
        <label>Server Seed: {g.serverSeed}</label>
        <label>{'\n'}Server Seed Hash: {g.serverSeedHash}</label>
        <HoldemGameAudioController
          currentUserIsActive={currentUserIsActive()}
          started={g.started}
          previousPokerAction={g.previousPokerAction}
          currentPlayers={g.holdemPlayers}
          pots={g.pots}
          tableCards={g.tableCards}
          timeLeftInTurn={g.afkTimer}
        />
      </div>
    </>
  );

  return (
    <>
      <Container className={styles['page-container-grid']} fluid={true}>
        <div className={styles['leave']}>
          <IconButton icon={'arrow-left'} link={RoutePaths.holdemPaths.Lobby} styles={{marginRight: '1rem'}}/>
          Leave
        </div>
        <div className={styles['table']} style={{backgroundImage: `url(${pokerTable})`}}>
          {currentGame ? renderAnimatedComponents(currentGame) : null}
        </div>
        {currentGame && currentGame.currentPlayer ? renderOtherInformation(currentGame, currentGame.currentPlayer.username) : null}
      </Container>
    </>
  );
}

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    joinTable: holdemSocketStore.actions.joinTable,
    leaveTable: holdemSocketStore.actions.leaveTable,
    joinSeat: holdemSocketStore.actions.joinSeat,
    startGame: holdemSocketStore.actions.startGame,
    sendPokerAction: holdemSocketStore.actions.sendPokerAction
  }, dispatch)});
const mapStateToProps = (state: WebState) => ({
  currentGame: gamesStore.selectors.getCurrentGame(state),
  getOldPlayerId: gamesStore.selectors.getPreviousPlayerId(state),
  currentUser: userStore.selectors.getCurrentUser(state),
  userToken: userStore.selectors.getCurrentUserToken
});
export default connect(mapStateToProps, mapDispatchToProps)(HoldemGame);
