import React, {useMemo, useState} from 'react';
import AnimatedPlayers from './AnimatedPlayers/AnimatedPlayers';
import AnimatedChips from './AnimatedChips/AnimatedChips';
import AnimatedPots from './AnimatedPots/AnimatedPots';
import AnimatedTableCards from './AnimatedTableCards/AnimatedTableCards';
import styles from './AnimatedHoldemGame.module.scss';
import {User} from '../../../../../../common/redux/entities/user';
import {Game} from '../../../../../../common/redux/holdem/games';
import useMeasure from 'react-use-measure';

interface Props {
  tableId: string;
  currentUser: User | null;
  game: Game;
}

const AnimatedHoldemGame = (props: Props) => {
  const {tableId, currentUser, game} = props;

  const [tableRef, {width: tableWidth, height: tableHeight}] = useMeasure();

  const [playerWidth, setPlayerWidth] = useState(tableWidth * 0.075);
  const [playerHeight, setPlayerHeight] = useState(tableHeight * 0.1);
  const [chipWidth, setChipWidth] = useState(tableWidth * 0.01);
  const [chipHeight, setChipHeight] = useState(tableWidth * 0.01);

  useMemo(() => {
      setPlayerWidth(tableWidth * 0.075);
      setPlayerHeight(tableHeight * 0.1);
      setChipWidth(tableWidth * 0.04);
      setChipHeight(tableHeight * 0.04);
    }, [tableWidth, tableHeight]
  );

  return (
    <>
      <div ref={tableRef} className={styles['table']}>
        <AnimatedPlayers
          tableId={tableId}
          currentUser={currentUser}
          playerWidth={playerWidth}
          playerHeight={playerHeight}
          currentGame={game}
        />
        <AnimatedChips
          chipWidth={chipWidth}
          chipHeight={chipHeight}
          currentSeat={game.currentPlayer?.seat ? game.currentPlayer.seat : 5}
          pots={game.pots}
          bigBlindAmount={game.bigBlindAmount}
        />
        <div className={styles['table-cards-pot-container']}>
          <AnimatedPots
            tablePots={game.pots}
            bigBlindAmount={game.bigBlindAmount}
            chipWidth={chipWidth}
            chipHeight={chipHeight}
          />
          <AnimatedTableCards tableCards={game.tableCards}/>
        </div>
      </div>
    </>
  );
};

export default AnimatedHoldemGame;
