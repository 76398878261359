import {Route, RouteProps} from 'react-router';
import React from 'react';
import {connect} from 'react-redux';
import {mapIsConnectedToProps, mapIsConnectedToPropsType} from '../../../common/redux/holdem/holdemSocket';
import Connecting from '../../../pages/Holdem/pages/components/Connecting';
import {PageHeader} from '../../layout/PageHeader/PageHeader';
import {Container} from 'react-bootstrap/index';
import styles from '../../../pages/Holdem/Holdem.module.scss';

type PrivateRouteProps = mapIsConnectedToPropsType & RouteProps & {
  component: any;
  renderPageHeader: boolean;
};

function PublicRouteWithWebsocketComponent({component: Component, authenticated, connected, renderPageHeader, ...rest}: PrivateRouteProps) {

  return (
    <Route
      {...rest}
      render={props =>
       connected ?
          (
            <Component {...props} />
          ) : (
           renderPageHeader ? (
             <PageHeader label='Csgo Holdem' removeMargin={true}>
               <Container fluid={true} className={styles['config-container']}>
                 <Connecting {...props}/>
               </Container>
             </PageHeader>
           ) : (
             <Connecting {...props}/>
           )
          )
      }
    />
  );
}

export const PublicRouteWithWebsocket = connect(mapIsConnectedToProps)(PublicRouteWithWebsocketComponent);
