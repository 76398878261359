import React from 'react';
import styles from './ChatBox.module.scss';
import {
  HoldemCard,
  HoldemDealerChat
} from '../../../../../common/redux/holdem/games';
import uuid from 'uuid';
import {getCard} from '../../../../../appTheme';

interface Props {
  currentUsername: string;
  chat: HoldemDealerChat;
}

const DealerChatBox = (props: Props) => {
  const {/*currentUsername,*/chat} = props;

  const renderDealerChatMessages = (dealerChat: HoldemDealerChat) => {
      return dealerChat.messages.map(chatMessage => {
        const key = `${chatMessage.user}-${uuid.v4().toString()}`;
        return (
          <div key={key} className={styles['dealer-message']}>
            {chatMessage.user}: {chatMessage.message}
            {chatMessage.cards ?
            <div className={styles['dealer-message-cards-container']}>
              {renderDealerChatCards(chatMessage.cards)}
            </div> : null}
          </div>
        );
        });
    };

  const renderDealerChatCards = (cards: HoldemCard[]) => {
      return cards.map(card => {
        const key = `${card.suit}${card.rank}-${uuid.v4().toString()}`;
        return (
          <img
            key={key}
            src={getCard(`${card.suit}${card.rank}`)}
            className={styles['dealer-message-cards']}
            alt={`${card.suit} ${card.rank}`}
          />
      );
      });
    };

  return (
    <>
      <div className={styles['chat-box']}>
          {chat ? renderDealerChatMessages(chat) : null}
      </div>
      </>
    );
};

export default DealerChatBox;
