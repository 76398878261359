import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import useSound from 'use-sound';
import {holdemGameAudioUrls} from '../../../../../../assets/audio/holdemGame/holdemGameAudioUrls';
import {
  EPokerAction,
  EPokerActionWithPlayerId,
  HoldemCard,
  HoldemPlayer,
  Pot
} from '../../../../../../common/redux/holdem/games';
import {Button} from 'react-bootstrap';
import AudioQueue from './AudioQueue';

interface Props {
  currentUserIsActive: boolean;
  started: number;
  previousPokerAction: EPokerActionWithPlayerId;
  currentPlayers: HoldemPlayer[];
  pots: Pot[];
  tableCards: HoldemCard[];
  timeLeftInTurn: number;
}

const HoldemGameAudioController = (props: Props) => {
  const {currentUserIsActive, previousPokerAction, started, pots, tableCards, timeLeftInTurn} = props;
  const previousProps = useRef<Props>(props);

  const [saltShakeAudio] = useSound(holdemGameAudioUrls.saltShake);
  const [muted, setMuted] = useState(false);

  // AFK timer sfx
  useEffect(() => {
      if (currentUserIsActive) {
        const delay = timeLeftInTurn >= 15 ? (timeLeftInTurn * 1000) - 15000 : 0;
        const timer = setTimeout(() => { clearTimeout(timer); saltShakeAudio(); }, delay);
        return () => clearTimeout(timer);
      }
    }, [currentUserIsActive, timeLeftInTurn, saltShakeAudio]
  );

  const currentPot = useCallback(() =>
      pots.length ? pots[pots.length - 1] : {} as Pot,
    [pots]);

  // Not sure if this will be correctly remade in useCallback since previousProps uses useRef
  const previousPot = useCallback(() =>
      previousProps.current.pots.length ? previousProps.current.pots[previousProps.current.pots.length - 1] : {} as Pot,
    []);

  const currentPotAmount = useCallback(() =>
      currentPot().amount ? currentPot().amount : 0,
    [currentPot]);

  const previousPotAmount = useCallback(() =>
      previousPot().amount ? previousPot().amount : 0,
    [previousPot]);

  // useCallbacks could be replaced with moving these functions into useMemo
  const getGameStartAudio = useCallback(() => {
      if (started !== previousProps.current.started) {
        if (started) {
          return holdemGameAudioUrls.shuffle;
        }
      }
    }, [started]
  );

  const getPotAndWagerAudio = useCallback(() => {
      if (pots.length > 0 && currentPot() !== previousPot()) {
        if (currentPotAmount() > previousPotAmount()) {
          return holdemGameAudioUrls.chipsSlide;
        }
      }
    }, [pots, currentPot, previousPot, currentPotAmount, previousPotAmount]
  );

  const getTableCardsAudio = useCallback(() => {
      if (tableCards !== previousProps.current.tableCards) {
        if (tableCards.length > previousProps.current.tableCards.length) {
          let audio: string[];
          if (tableCards.length === 3) {
            audio = tableCards.map(() => {
              return holdemGameAudioUrls.cardSlide;
            });
            return audio;
          }
          audio = [holdemGameAudioUrls.cardSlide];
          return audio;
        }
      }
    }, [tableCards]
  );

  const getPokerActionAudio = useCallback(() => {
      if (previousPokerAction) {
        if (EPokerAction[previousPokerAction.action] === 'Fold') {
          return holdemGameAudioUrls.fold;
        } else if (EPokerAction[previousPokerAction.action] === 'Check') {
          return holdemGameAudioUrls.check;
        }
        return holdemGameAudioUrls.chipsDrop;
      }
    }, [previousPokerAction]
  );

  const [holdemAudioQueue] = useMemo(() => {
      const orderedAudio: string[] = [];

      if (props && props !== previousProps.current) {

        const gameStartAudio = getGameStartAudio();
        const previousPokerActionAudio = getPokerActionAudio();
        const potAudio = getPotAndWagerAudio();
        const tableCardsAudio = getTableCardsAudio();

        if (gameStartAudio) {
          orderedAudio.push(gameStartAudio);
        }
        if (previousPokerActionAudio) {
          orderedAudio.push(previousPokerActionAudio);
        }
        if (potAudio) {
          orderedAudio.push(potAudio);
        }
        if (tableCardsAudio && tableCardsAudio.length) {
          tableCardsAudio.map((audio) => { return orderedAudio.push(audio); });
        }
        if (currentUserIsActive) {
          orderedAudio.push(holdemGameAudioUrls.bell);
        }
      }
      previousProps.current = props;
      return [orderedAudio];
    }, [props, currentUserIsActive, getGameStartAudio, getPokerActionAudio, getPotAndWagerAudio, getTableCardsAudio]
  );

  return (
    <>
      <AudioQueue audioUrls={holdemAudioQueue}/>
      <Button onClick={() => setMuted(!muted)}>
        Toggle Audio ({muted ? 'muted' : 'not muted'})
      </Button>
    </>
  );
};

export default HoldemGameAudioController;
