import React from 'react';
import {RoutePaths} from '../../../../router/RoutePaths';
import {Link} from 'react-router-dom';
import styles from './HomeNavbar.module.scss';

interface HeaderLink {
  path: string;
  text: string;
}

const HeaderLinks: HeaderLink[] = [
  {path: RoutePaths.account, text: 'My Account'},
  {path: RoutePaths.balance, text: 'Cash in/out'},
  {path: RoutePaths.holdemPaths.Lobby, text: 'Table Viewer'},
  {path: RoutePaths.about, text: 'About Us'}
  /*{path: RoutePaths.logout, text: 'Log out'}*/
];

const buildNavbarContentStyle = () => {
  return HeaderLinks.map((link, i) => (
    <React.Fragment key={link.path}>
      <Link key={link.path} to={link.path}>{link.text}</Link>
      {i + 1 !== HeaderLinks.length ? <div key={`${link.path}-${i}`} className={styles['spacer']}> | </div> : null}
    </React.Fragment>
  ));
};

const HomeNavbar = () => {

  return (
    <div className={styles['navbar']}>
      {buildNavbarContentStyle()}
    </div>
  );
};

export default HomeNavbar;
