import React, {useEffect, useRef, useState} from 'react';

interface Props {
  audioUrls: string[];
}

const AudioQueue = (props: Props) => {
  const {audioUrls} = props;
  const audioRef = useRef<HTMLAudioElement | null>(null);

  const [currentSource, setCurrentSource] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);

  // Reset queue on props change
  useEffect(() => {
    if (audioUrls.length) {
      setCurrentIndex(0);
      setCurrentSource(audioUrls[0]);
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.load();
        audioRef.current.play();
      }
    }
  }, [audioUrls]);

  const handleNextSound = () => {
    if (currentIndex < audioUrls.length - 1) {
      setCurrentSource(audioUrls[currentIndex + 1]);
      setCurrentIndex(currentIndex + 1);
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.load();
        audioRef.current.play();
      }
    }
  };

  return (
    <>
      <audio ref={audioRef} onEnded={() => handleNextSound()}>
        <source src={currentSource}/>
      </audio>
    </>
  );
};

export default AudioQueue;
