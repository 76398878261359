import bell from './bell.mp3';
import cardDrop from './cardDrop.mp3';
import cardSlide from './cardSlide.mp3';
import check from './check.mp3';
import chipsDrop from './chipsDrop.mp3';
import chipsSlide from './chipsSlide.mp3';
import fold from  './fold.mp3';
import saltShake from './saltShake.mp3';
import shuffle from './shuffle.mp3';

export const holdemGameAudioUrls = {
  bell: bell,
  cardDrop: cardDrop,
  cardSlide: cardSlide,
  check: check,
  chipsDrop: chipsDrop,
  chipsSlide: chipsSlide,
  fold: fold,
  saltShake: saltShake,
  shuffle: shuffle
};
