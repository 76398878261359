import React, {useMemo} from 'react';
import {animated, config, useTransition} from '@react-spring/web';
import {bindActionCreators, Dispatch} from 'redux';
import {holdemSocketStore} from '../../../../../../../common/redux/holdem/holdemSocket';
import {connect} from 'react-redux';
import {EPokerStatus, Game, HoldemCard, HoldemPlayer} from '../../../../../../../common/redux/holdem/games';
import {Button} from 'react-bootstrap/es';
import uuid from 'uuid';
import {getCard} from '../../../../../../../appTheme';
import styles from './AnimatedPlayers.module.scss';
import HoldemTimer from '../../HoldemTimer';
import BuyInModal from '../../BuyInModal';
import {User} from '../../../../../../../common/redux/entities/user';
import {PlayerAnimationProps, calculateSeatPosition} from '../PlayerAndChipPositions';

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

interface Props extends DispatchProps {
  tableId: string;
  currentUser: User | null;
  playerWidth: number;
  playerHeight: number;
  currentGame: Game;
}

const AnimatedPlayers = (props: Props) => {
  const {tableId, currentUser, playerWidth, playerHeight, currentGame, actions: {joinSeat}} = props;

  const [seatGrid] = useMemo(() => {
    const currentSeat = currentGame.currentPlayer?.seat ? currentGame.currentPlayer.seat : 5;
    const seatPositions: PlayerAnimationProps[] = [];
    let relativeSeat = currentSeat > 0 ? 0 : 1;
    while (relativeSeat < 8) {
      let seat = currentSeat + relativeSeat;
      if (seat > 8) {seat = seat - 8; }
      seatPositions.push(calculateSeatPosition(seat, relativeSeat + 1, playerWidth, playerHeight));
      relativeSeat++;
    }
    return [seatPositions];
  }, [currentGame, playerHeight, playerWidth]);

  const seatTransitions = useTransition(seatGrid, {
    key: (item: PlayerAnimationProps) => item.seat,
    from: ({left, bottom, transform}) => ({left, bottom, transform, opacity: 0}),
    enter: ({left, bottom, transform}) => ({left, bottom, transform, opacity: 1}),
    update: ({left, bottom, transform}) => ({left, bottom, transform}),
    leave: {bottom: 0, opacity: 0},
    config: config.default,
    trail: 25
  });

  const renderButtonOrPlayer = (seat: number) => {
    if (currentGame !== null) {
      const player = currentGame.holdemPlayers.find((p: HoldemPlayer) => p.seat === seat);
      if (player) {
        return (
          renderPlayer(player, currentGame)
        );
      }
      if (!currentGame.started) {
        return (
          <Button onClick={() => joinSeat(tableId, seat)}>Seat {seat}</Button>
        );
      }
      return (
        <Button onClick={() => false} disabled={true}>Seat {seat}</Button>
      );
    }
  };

  const renderPlayer = (p: HoldemPlayer, g: Game) =>  {
    return (
      <div className={styles['player-info-container']} style={{width: playerWidth, height: playerHeight}}>
        <div className={styles['player-cards']}>{renderPlayerCards(p.cards)}</div>

        <div className={styles['player-rounded-box']} style={{background: p.seat === g.activePlayerSeat ? 'red' : 'darkslategray'}}>
          <div>{p.username}</div>
          <div className={styles['player-rounded-box-chips']}>Chips: {p.chips}</div>
        </div>

        <div>Status: {EPokerStatus[p.pokerStatus]}</div>
        {renderBlind(p.seat, g.smallBlindSeat, g.bigBlindSeat, g.bigBlindAmount)}
        {p.seat === g.activePlayerSeat ? <HoldemTimer secondsToCountdownFrom={30} showVisualWarning={true} secondsLeft={g.afkTimer} timeout={false}/> : null}
        <div>Gold: {p.chips}</div>
        {
          currentUser?.id.toString() === p.id ? <BuyInModal tableId={tableId} currentChips={p.chips} buyInAmount={g.bigBlindAmount * 50}/> : null
        }
      </div>
    );
  };

  const renderBlind = (seat: number, smallBlindSeat: number, bigBlindSeat: number, bigBlindAmount: number) => {
    if (seat === bigBlindSeat) {
      return (
        <div>Big blind: {bigBlindAmount}</div>
      );
    }
    if (seat === smallBlindSeat) {
      return (
        <div>Small blind: {bigBlindAmount / 2}</div>
      );
    }
  };

  const renderPlayerCards = (cards: HoldemCard[]) => {
    return cards.map(card => {
      return (
        <img
          className={styles['card']}
          key={`${card.suit}${card.rank}-${uuid.v4().toString()}`}
          src={getCard(`${card.suit}${card.rank}`)}
          style={{width: `${playerWidth / 2}px`}}
          alt={`${card.suit} ${card.rank}`}
        />
      );
    });
  };

  return (
    <>
      {seatTransitions((style, item) => (
        <animated.div className={styles['transition-component']} style={style}>
          <div className={styles['transition-component-data']}>
            {renderButtonOrPlayer(item.seat)}
          </div>
        </animated.div>
      ))}
    </>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    joinSeat: holdemSocketStore.actions.joinSeat
  }, dispatch)});

export default connect(null, mapDispatchToProps)(AnimatedPlayers);
