import {HoldemMessage} from '../common/redux/holdem/holdemSocket';

function makeCommand(command: string, data: string): HoldemMessage {
  return {
    command: command,
    data: data
  };
}
// TODO Move this to a factory folder
export function getLobbyListCommand() {
  return makeCommand('getLobbyList', '');
}

export function joinTableCommand(tableId: string, token: string | null) {
  return makeCommand('joinTable', JSON.stringify({tableId: tableId, token: token}));
}

export function leaveTableCommand() {
  return makeCommand('leaveTable', '');
}

export function joinSeatCommand(tableId: string, seat: number) {
  return makeCommand('joinSeat', JSON.stringify({tableId: tableId, seat: seat.toString()}));
}

export function buyBackInCommand(tableId: string, amount: string) {
  return makeCommand('buyBackIn', JSON.stringify({tableId: tableId, amount: amount}));
}

export function startGameCommand() {
  return makeCommand('startGame', '');
}

export function sendPokerActionCommand(tableId: string, action: number, value: string) {
  return makeCommand('pokerAction', JSON.stringify({tableId: tableId, action: action, value: value}));
}

export function sendHoldemChatCommand(tableId: string, message: string)  {
  return makeCommand('holdemChat', JSON.stringify({tableId: tableId, message: message}));
}
