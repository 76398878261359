import React, {useMemo, useState} from 'react';
import styles from './HoldemChatBox.module.scss';
import {
  HoldemChatMessage,
  HoldemDealerChat
} from '../../../../../../common/redux/holdem/games';
import {Button} from 'react-bootstrap/es';
import ChatBox from '../ChatBox';
import DealerChatBox from '../DealerChatBox';
import IconButton from '../../../../../../components/util/widgets/IconButton/IconButton';

interface Props {
  tableId: string;
  currentUsername: string;
  playerChat: HoldemChatMessage[];
  dealerChats: HoldemDealerChat[];
}

export const HoldemChatBox = (props: Props) => {
  const {tableId, currentUsername, playerChat, dealerChats} = props;
  const [currentChat, setCurrentChat] = useState(false);
  const [dealerEnabled, setDealerEnabled] = useState(false);
  const [currentDealerChat, setCurrentDealerChat] = useState(0);

  useMemo(() => {
      if (dealerChats) {
        setDealerEnabled(true);
      }
    }, [dealerChats]
  );

  const renderCurrentChat = () => {
    if (!currentChat && playerChat) {
      return (
        <ChatBox
          tableId={tableId}
          currentUsername={currentUsername}
          chatMessages={playerChat}
        />
      );
    } else {
      return (
        <DealerChatBox currentUsername={currentUsername} chat={dealerChats[currentDealerChat]}/>
      );
    }
  };

  const renderHoldemChatActions = () => {
    return (
      <>
        {dealerEnabled ? <Button onClick={() => setCurrentChat(!currentChat)}>Toggle Chat</Button> : null}
      </>
    );
  };

  const renderDealerChatArrows = () => {
    return (
      <div className={styles['arrows-container']}>
        <IconButton
          styles={{padding: '1rem'}}
          icon='chevron-left'
          onClick={() =>
            currentDealerChat > 0 ? setCurrentDealerChat(currentDealerChat - 1) : null}
          size={'1x'}
        />
        <IconButton
          styles={{padding: '1rem'}}
          icon='chevron-right'
          onClick={() =>
            currentDealerChat < dealerChats.length - 1 ? setCurrentDealerChat(currentDealerChat + 1) : null}
          size={'1x'}
        />
      </div>
    );
  };

  return (
    <>
      <div className={styles['chat-with-dealer-controls']}>
        <div style={{display: 'flex', flex: '1', maxHeight: '100%'}}>
          {renderCurrentChat()}
        </div>
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
          {currentChat ? renderDealerChatArrows() : null}
          {renderHoldemChatActions()}
        </div>
      </div>
    </>
  );
};

export default HoldemChatBox;
