import React, {useState} from 'react';
import {animated, config, useSprings} from '@react-spring/web';
import {HoldemCard} from '../../../../../../../common/redux/holdem/games';
import uuid from 'uuid';
import {getCard} from '../../../../../../../appTheme';
import styles from './AnimatedTableCards.module.scss';

interface Props {
  tableCards: HoldemCard[];
}

const AnimatedTableCards = (props: Props) => {
  const {tableCards} = props;
  const [Delay, setDelay] = useState(true);

  const calculateDelay = (i: number, length: number): number => {
    if (length === 0) {
      setDelay(true);
    }
    if (!Delay) {
      return 0;
    }
    // Set to no delay when only one card will come out
    if (i === tableCards.length) { setDelay(false); }
    return i * 250;
  };

  const [cardSprings] = useSprings(tableCards.length, i => ({
    from: {transform: 'translate(20%, 10%)', opacity: 0},
    to: {transform: `translate(0%, 0%)`, opacity: 1},
    delay: calculateDelay(i, tableCards.length),
    config: config.molasses
  }));

  return (
    <>
      <div className={styles['table-cards-container']}>
      {cardSprings.map(({transform, opacity}, i) => (
        <animated.img
          className={styles['card']}
          style={{transform, opacity}}
          key={`${tableCards[i].suit}${tableCards[i].rank}-${uuid.v4().toString()}`}
          src={getCard(`${tableCards[i].suit}${tableCards[i].rank}`)}
          alt={`${tableCards[i].suit} ${tableCards[i].rank}`}
        />
      ))}
      </div>
    </>
  );
};

export default AnimatedTableCards;
