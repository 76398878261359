import {createStandardActions, GetActions, placeholder, readonly, standardItemsReducer} from '../utils';
import {combineReducers} from 'redux';
import {createStandardSelectors, getEntities, selector} from '../selectors';
import {createAction, createReducer} from 'typesafe-actions';

export interface Bank {
  id: number;
  userId: number;
  balance: number;
}

interface BankState {
  currentBank: Bank | null;
  items: BankItems;
}

export interface BankItems {
  [key: number]: Bank;
}

const currentBankActions = {
  setCurrentBank: createAction('BANK/SET_CURRENT_BANK')<Bank | null>()
};

const actions = createStandardActions(placeholder<Bank>(), 'BANK/SET', 'BANK/SAVE');
const selectors = createStandardSelectors(placeholder<Bank>(), s => getEntities(s).banks);

export type BankActions = GetActions<typeof actions>;
type CurrentBankActions = GetActions<typeof currentBankActions>;

export const banks = combineReducers<BankState>({
  currentBank: createReducer<Bank|null, CurrentBankActions>(null)
    .handleAction(currentBankActions.setCurrentBank, (state, action) => action.payload),
  items: standardItemsReducer<Bank, BankActions>(actions)
});

export const bankStore = readonly({
  selectors: {
    ...selectors,
    getCurrentBank: selector(s => selectors.getState(s).currentBank)
  },
  actions: {
    ...actions,
    ...currentBankActions
  }
});
