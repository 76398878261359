import React from 'react';
import {connect} from 'react-redux';

function About() {
  return (
    <>
      KAZAKHSTAN:

        MINISTRY OF INFORMATION PRESENTS

        A KAZAKHSTAN:

        TELEVISION PRODUCTION

        IN ASSOCIATION WITH

        BAGATOV FILMS:

        My name Borat.

        I like you. I like sex.

        It's nice.

        This my country of Kazakhstan.

        It locate between Tajikistan

        and Kyrgyzstan...

        ...and a**holes Uzbekistan.

        This my town of Kuzcek.

        This Urkin, the town rapist.

        Naughty, naughty.

        Over here, our town kindergarten.

        And here live Mukhtar Sakanov...

        ...town mechanic and abortionist.

        This my house. Entry, please.

        He is my neighbor,

        Nursultan Tulyakbay.

        He is pain in my a**holes.

        I get a window from a glass,

        he must get a window from a glass.

        I get a step, he must get a step.

        I get a clock radio, he cannot afford.

        Great success.

        This is Natalya.

        She is my sister.

        She is number four prostitute

        in all of Kazakhstan.

        Nice.

        This is my mother.

        She oldest woman

        in whole of Kuzcek.

        She is 43. I love her.

        And this my wife, Oxana.

        She's boring.

        What you say about me,

        you skinny piece of sh*t?

        Not now, please.

        Why don't you do something

        usefuI and dig your mother a grave.

        Come in here, please. Ignore.

        This is where I lives. My bed.

        This is a VCR recorder.

        And this play cassettes.

        Now I show you outside

        from my houses.

        My hobbies, Ping-Pong...

        ...sunbathe...

        ...disco dance...

        ...and on weekends,

        I traveI to capitaI city...

        ...and watch ladies

        while they make toilet.

        My profession, work as a

        television reporter for Kazakhstan.

        Please, you see.

        THE RUNNING OF THE JEW 2004

        Here comes the Jew.

        It's a big one this year.

        Whoaaa...

        He nearly got the money there.

        Wait, here comes Mrs. Jew.

        She's stopped.

        Is she? Is she?

        Here it comes.

        She's laid a Jew egg.

        Go kids! Crush that

        Jew chick before he hatches!

        Although Kazakhstan glorious country,

        it have problem too.

        Economic, sociaI and Jew.

        This why Ministry of Information

        have decide to send me to U.S. and A...

        ...greatest country in the world,

        to learn lessons for Kazakhstan.

        I will traveI with most venerable

        producer, Azamat Bagatov.

        Azamat.

        No, not film me!

        Film him.

        Urkin, not too much raping...

        Humans only.

        Doltan, I'll get you

        a new arm in America.

        I go to America!

        America!

        Wave goodbye to your

        clock radio, a**hole!

        If you cheat on me,

        I will come over there...

        ...and snap off your cock.

        JFK INTERNATIONAL AIRPOR New York City

        I arrived in America's airport

        with clothings, U.S. dollars...

        ...and a jar of Gypsy tears

        to protect me from AIDS.

        Stand clear of the closing doors,

        please.

        Hello, my name Borat.

        I not American. I new in town.

        Nice to meet you.

        Hello, nice meet you.

        Hey, what your name?

        My name is mind your own

        f***ing business.

        Stand clear of the closing doors,

        please.

        Oh, hello. Nice meet you.

        My name Borat.

        - What's happening?

        - Nice meet you.

        Get the f*** out of here

        before I break your jaw, bro.

        - Yo, step the f*** off, bro.

        - Okay. Okay.

        - You're f***ing with the wrong one, man.

        - Okay. Sorry.

        Oh, sh*t.

        Okay, okay, wait.

        I get it. Please, relax.

        I'll get him! CarefuI, he bite.

        - Hey, man, what are you doing?

        - Okay, relax.

        Okay. Okay.

        Okay, okay, relax.

        Okay. Okay, wait.

        Okay, no problem. Sorry.

        Welcome to the Wellington HoteI.

        GUY BORGES,Manager, Wellington HoteI

        Do you want to pay for the entire stay now?

        - I pay for one night. How much?

        - Fine.

        One night is $ 117.13.

        We'll call it 85.

        No, we can call it 117.

        Let me get the door for you.

        Come on in.

        Very nice.

        Very nice room.

        We're not in the room yet, sir.

        Hold on.

        You might wanna repack.

        We're gonna be moving again shortly.

        I will not move to a smaller room.

        Sir, this is your floor.

        I'm gonna take you to your room.

        - This is not my room?

        - This is the elevator.

        It takes you to the floor

        where your room is.

        Nice. Nice.

        King in the castle.

        King in the castle.

        I have a chair. I have a chair.

        Oh, go do this. Go do this.

        King in the castle.

        Hello, nice meet you.

        My name Borat.

        I am new in town.

        - Get away from me.

        - I just- I kiss you, say hello.

        It arrive.

        Hello, my name Borat.

        I am new in town.

        - I say hello and I-

        - Do not touch me.

        - Do not get near my face.

        - I kiss you.

        Yeah, you kiss me,

        I'll pop you in the f***ing balls.

        What mean, "balls"?

        Very nice.

        Very nice. How much?

        Hello, nice meet you. My name Borat.

        Get away!

        What are you doing?

        Wait, I want to say hello.

        What is the problem?

        This has been

        most happiest day of my lifes.

        I was very excite

        to start my reportings.

        America is known for its

        sense of humor.

        UN survey say Kazakhstan

        have 98th lowest humor.

        We must improve.

        Hurry, hurry.

        Just dry him, no hand relief.

        How is my back p*ssy?

        Not bad. Moist.

        So what time this interview?

        Soon, my friend.

        Gently.

        Enough!

        - Hello. My name's Pat Haggerty.

        "PAT HAGGERTY - Humor Coach"

        - Nice meet you. Borat.

        - Nice to meet you.

        Should I make a joke

        about my mother-in-law?

        Yes. In America,

        that's a very popular joke.

        - Do you have a mother-in-law joke?

        - Yes.

        I had sexy-time

        with my mother-in-law.

        - A what time?

        - Sexy-time.

        I made sexy-time

        with my mother-in-law.

        - You had sex with your mother-in-law?

        - Yes.

        I don't think that Americans

        would find that funny.

        - No, it is not a joke.

        - Yeah.

        We're talking about humor.

        Yes, you asked me about

        my mother-in-law.

        Do you have a joke about her?

        No, why make a joke

        on a mother-in-law?

        Do you ever laugh on people

        with a retardation?

        Here in America

        we try not to make fun of...

        ...or be funny with things

        that people don't choose.

        But perhaps you have not seen

        someone with a very funny retardation.

        My brother, Bilo,

        have a very funny retardation.

        MentaI retardation causes a lot of pain

        and hardship for a lot of families.

        Sometime my sister, she show her

        vagina to my brother, Bilo, and say:

        "You will never get this!

        You will never get this! "

        He behind his cage, crazy, crazy.

        Everybody laugh.

        She go, "You never get this! "

        But one time, he break cage

        and he get this.

        And then we all laugh.

        High five!

        Now...

        No, that would not be funny

        in America, okay?

        What is "not" jokes?

        A "not" joke is when we're trying

        to make fun of something...

        ...and what we do is we make

        a statement that we pretend is true...

        ...but, at the end, we say, "Not,"

        which means it's not true.

        So teach me how to make one.

        - All right, what color is your suit?

        - This suit is gray.

        Gray, I would call it blue, okay?

        - It's gray.

        - All right, it's blue-gray.

        But it's certainly

        not black, right?

        - Let's say it's gray, but-

        - It is gray.

        Okay, so a "not" joke,

        I would say:

        "That suit is black. Not! "

        This suit is not black.

        - No, no, "not" has to be the end.

        - Oh, okay.

        This suit is black not.

        This suit is black, pause-

        You know what a pause is?

        - Yes.

        - This suit is black. Not!

        This suit is black. Pause. Not.

        No, you don't say "pause. "

        This suit is black.

        That's a pause. Not!

        This suit is black.

        Okay...

        - I don't- I'm not-

        - Not!

        Everybody say U.S.A. television

        much better...

        ...but this I watch for three hours,

        do not change.

        There's a remote controI here.

        Push these two arrows

        to change the channeI.

        I got him, I got him, I got him!

        I have the urge to

        bury something else.

        Yes!

        - I love you.

        - Oh, I love you too, Jamie.

        I love you.

        - Do you believe in magic, Miss-?

        - Parker, C.J.

        It's a pleasure to meet you, C.J.

        Be carefuI! Be carefuI, C.J.!

        This C.J. was like no Kazakh woman

        I had ever seen.

        She had golden hairs,

        teeth as white as pearls...

        ...and the a**hole of a 7-year-old.

        For the first time in my lifes...

        ...I was in love.

        Get up! Get up!

        Why aren't you ready!

        We have people to interview today.

        I understand.

        Learn what you can from

        this women's group.

        My hair?

        It's beautifuI. Don't worry.

        In Kazakhstan it is illegaI for more than

        five woman to be in the same place...

        ...except for in brotheI or in grave.

        In the U.S. and A., many womens meet

        in groups called Feminists.

        I find more.

        "VETERAN FEMINISTS OF AMERICA"

        So, what means this feminism?

        It's the theory that women

        should be equaI to men...

        ...in matters economic, sociaI-

        - Now you are laughing.

        - Yes.

        That is the problem.

        Do you think a woman

        should be educate?

        Definitely.

        But is it not a problem that a woman

        have a smaller brain than a man?

        That is wrong.

        But the government scientist,

        Dr. Yamak,prove it's the size of squirreI.

        Your government's scientist?

        - Yes, Dr. Yamak.

        - He's wrong. He's wrong.

        Give me a smile, baby.

        Why angry face?

        Well, what you're saying

        is very demeaning.

        - Do you know the word "demeaning"?

        - No.

        We are saying to you...

        I could not concentrate on what

        this old man was saying.

        All I could think about was this

        lovely woman in her red water-panties.

        Who was this C.J.?

        Last night I see in my hoteI room...

        ...a woman called C.J.

        on the television.

        - Do you know her?

        - No.

        She from a town

        called Baywatches.

        - She's just on television.

        - Her name is Pamela.

        - Do she live here in New York City?

        - She lives in California.

        - In the California.

        - He's gonna look her up.

        Okay, can we finish now?

        Listen, pussycat, smile a bit.

        - All right. That's it. I'm done.

        - We're finished. We have to leave.

        Although I was obsessed by this C.J.,

        I could not pursue her...

        ...or else my wife

        would snap off my cock.

        Mr. Sagdiyev?

        - Yes?

        - I have a telegram for you.

        - You can read?

        - Yes, I can.

        "Dear Borat Sagdiyev...

        ...your wife, Oxna...

        ...was walking your retarded Bilo

        in the woods...

        ...when a bear attacked

        and violated and break her.

        She is now dead. "

        You say my wife is dead?

        This is what it's...

        Yes, sir. I'm sorry to inform you,

        but that's what the telegram says.

        High five! Great!

        What do you mean, California?

        I have arranged all our filming

        for New York.

        But we need to leave New York

        to find the reaI America:

        Rodeos, cowboys...

        It will be better for our documentary.

        But why California?

        What's there?

        PearI Harbor is there...

        And so is Texas.

        Eventually, I persuade Azamat

        that we would travel to California...

        ...and make our reportings

        along the way.

        He insists we not fly in case the Jews

        repeated their attack of 9-11.

        Okay, I'll find another way

        for us to get there.

        My name is Mike.

        I'm gonna be your driving instructor.

        "MICHAEL PSENICSKA,Perry Hall Driving SchooI"

        Welcome to our country, okay.

        - My name Borat.

        - Okay, okay. Good, good.

        Well, I'm not used to that,

        but that's fine.

        Now, you do know

        how to drive a little bit?

        - Yes.

        - Yes. Put it in D.

        - What?

        - Drive.

        Now, wait a second. Wait a second.

        - Have you driven a car before?

        - Yes, many times.

        All right, let's go this way.

        I don't want you hitting anybody.

        Use two hands, now.

        - What?

        - Two hands.
    </>
  );
}

const mapDispatchToProps = () => ({});
const mapStateToProps = () => ({});
export default connect(mapStateToProps, mapDispatchToProps)(About);
