import * as React from 'react';
import {Switch, Route, match} from 'react-router-dom';
import {convertComponentPaths, RoutePath, RoutePaths} from '../../router/RoutePaths';
import NotFound from '../NotFound/NotFound';
import {RouteRenderer} from '../../router/RouteRenderer';
import HoldemGame from './pages/HoldemGame/HoldemGame';

interface Props {
  match: match;
}

const holdemGameRoutes: RoutePath[] = [
  ...convertComponentPaths(
    {component: HoldemGame, paths: [RoutePaths.holdemGamePaths.Play]},
  )
];

export const HoldemGameRoutes = (props: Props) => (
  <Switch>
    <RouteRenderer routePaths={holdemGameRoutes}/>
    <Route component={NotFound}/>
  </Switch>
);
