import React, {useMemo, useState, useCallback, useRef} from 'react';
import {animated, config, useTransition} from '@react-spring/web';
import {Pot, Wager} from '../../../../../../../common/redux/holdem/games';
import styles from './AnimatedChips.module.scss';
import RenderChips from '../../RenderChips';
import {PlayerAnimationProps, calculateSeatPosition, chipPositions} from '../PlayerAndChipPositions';

interface ChipAnimationProps extends PlayerAnimationProps {
  chips: number;
  opacity: number;
  displayText: boolean;
}

interface Props {
  chipHeight: number;
  chipWidth: number;
  currentSeat: number;
  pots: Pot[];
  bigBlindAmount: number;
}

const AnimatedChips = (props: Props) => {
  const {chipWidth, chipHeight, currentSeat, pots, bigBlindAmount} = props;

  // useCallback may not be necessary
  const currentPotAmount = useCallback(() => {
      if (pots.length) {
        return pots[pots.length - 1].amount;
      }
      return 0;
    }, [pots]
  );

  const currentWagers = useCallback(() => {
      if (pots.length) {
        return pots[pots.length - 1].wagers;
      }
      return [];
    }, [pots]
  );

  const previousWagers = useRef<Wager[]>();
  const previousPotAMount = useRef<number>(currentPotAmount());
  const [moveChipsToPot, setMoveChipsToPot] = useState(false);
  const [moveChipsToPlayers, setMoveChipsToPlayers] = useState(currentWagers().length <= 0);

  const currentWagerAmount = useCallback((seat: number) => {
    if (currentWagers().length > 0) {
      const wager = currentWagers().find((w: Wager) => w.player.seat === seat);
      if (wager) {
        return wager.amount;
      }
    }
    return 0;
    }, [currentWagers]
  );

  const previousWagerAmount = useCallback((seat: number) => {
    if (previousWagers.current) {
      const wager = previousWagers.current.find((w: Wager) => w.player.seat === seat);
      if (wager) {
        return wager.amount;
      }
    }
    return 0;
    }, []
  );

  const [chipGrid] = useMemo(() => {
    // tslint:disable-next-line:no-shadowed-variable
    const calculateChipAnimationProps = (seat: number, relativeSeat: number): ChipAnimationProps => {
      let chips = currentWagerAmount(seat);
      let left = '';
      let bottom = '';
      let opacity = 1;
      let displayText = true;
      if (moveChipsToPot) {
        // Show previous wager until finished moving to pot
        chips = previousWagerAmount(seat);
        left = chipPositions.centerColumn;
        bottom = '55%';
        opacity = 0;
        displayText = false;
      } else if (moveChipsToPlayers || chips === 0) {
        // TODO calculateChipPosition?
        const seatAnimationProps = calculateSeatPosition(seat, relativeSeat, chipWidth, chipHeight);
        return {...seatAnimationProps, chips: chips, opacity: 0.5, displayText: false};
      } else {
        // If a wager is found and should not move to the pot
        // relativeSeat is clockwise starting at the bottom and center player (the user)
        if (relativeSeat === 1 || relativeSeat === 5) {
          left = chipPositions.centerColumn;
          bottom = relativeSeat === 1 ? chipPositions.bottomRow : chipPositions.topRow;
        } else if (relativeSeat === 2 || relativeSeat === 4) {
          left = chipPositions.leftCenterColumn;
          bottom = relativeSeat === 2 ? chipPositions.bottomRow : chipPositions.topRow;
        } else if (relativeSeat === 3 || relativeSeat === 7) {
          relativeSeat === 3 ? left = chipPositions.leftColumn : left = chipPositions.rightColumn;
          bottom = chipPositions.centerRow;
        } else if (relativeSeat === 6 || relativeSeat === 8) {
          left = chipPositions.rightCenterColumn;
          bottom = relativeSeat === 8 ? chipPositions.bottomRow : chipPositions.topRow;
        }
      }
      return {chips: chips, seat: seat, left: left, bottom: bottom, width: chipWidth,
        height: chipHeight, transform: 'translate(-50%, 50%)', opacity: opacity, displayText: displayText};
    };

    // Main useMemo function
    const chipAnimationProps: ChipAnimationProps[] = [];
    let relativeSeat = currentSeat > 0 ? 0 : 1;
    // Check if chips should move to pot before creating grid
    if (currentPotAmount() !== previousPotAMount.current && !currentWagers().length) {
      setMoveChipsToPot(true);
    }
    while (relativeSeat < 8) {
      let seat = currentSeat + relativeSeat;
      if (seat > 8) { seat = seat - 8; }
      chipAnimationProps.push(calculateChipAnimationProps(seat, relativeSeat + 1));
      relativeSeat++;
    }
    if (currentWagers().length) { previousWagers.current = currentWagers(); }
    previousPotAMount.current = currentPotAmount();
    return [chipAnimationProps];
  }, [currentSeat, currentPotAmount, currentWagerAmount, previousWagerAmount, currentWagers, moveChipsToPot, moveChipsToPlayers, chipHeight, chipWidth]);
  // End of useMemo

  // Assign render grid to new values to animate to
  const chipTransitions = useTransition(chipGrid, {
    key: (item: ChipAnimationProps) => item.seat,
    from: ({left, bottom, transform, opacity}) => ({left, bottom, transform, opacity}),
    enter: ({left, bottom, transform, opacity}) => ({left, bottom, transform, opacity}),
    update: ({left, bottom, transform, opacity}) => ({left, bottom, transform, opacity}),
    leave: {bottom: 0, opacity: 0},
    config: config.slow,
    trail: 25,
    onRest: () => {
      if (moveChipsToPot) {
        setMoveChipsToPot(false); setMoveChipsToPlayers(true);
      } else if (moveChipsToPlayers) {
        setMoveChipsToPlayers(false);
      }
    }
  });

  const renderWager = (chips: number, displayText: boolean) => {
      return (
        <RenderChips
          currentWager={chips}
          bigBlindAmount={bigBlindAmount}
          displayText={displayText}
          maxHeight={chipHeight}
          maxWidth={chipWidth}
        />
      );
  };

  return (
    <>
      {chipTransitions((style, item) => (
        <animated.div className={styles['transition-component']} style={style}>
          <div className={styles['transition-component-data']}>
            {renderWager(item.chips, item.displayText)}
          </div>
        </animated.div>
      ))}
    </>
  );
};

export default AnimatedChips;
