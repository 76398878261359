import React from 'react';
import {Redirect} from 'react-router-dom';
import {RoutePaths} from '../../../router/RoutePaths';

export class HomeRedirect extends React.Component {
  render() {
    return (
      <Redirect to={RoutePaths.Holdem}/>
    );
  }
}
