import React, {useState} from 'react';
import styles from './HomeGridLayout.module.scss';
import {Link} from 'react-router-dom';
import {RoutePaths} from '../../../router/RoutePaths';
import IconButton from '../../../components/util/widgets/IconButton/IconButton';
import {getLogoLink} from '../../../appTheme';
import {Button, Container} from 'react-bootstrap';
import ChatBox from '../../Holdem/pages/components/ChatBox/ChatBox';
import HomeNavbar from './HomeNavbar/HomeNavbar';
import {LinkContainer} from 'react-router-bootstrap';

interface Props {
  label: string;
  children: any;
}

export function HomeGridLayout(props: Props) {
  // temporary until chat animation
  const [chatOpacity, setChatOpacity] = useState(1);

  const renderHeaderAndContent = () => (
    <>
      <div className={styles['header']}>
        <Link to={RoutePaths.Home}>
          <img src={getLogoLink()} alt={props.label} style={{maxHeight: '5rem' }} />
        </Link>
      </div>
      <div className={styles['navbar']}>
        <HomeNavbar/>
      </div>
      <div className={styles['content']} style={{border: '3px solid grey', borderRadius: '2rem'}}>
        {props.children}
      </div>
    </>
  );

  const renderChatColumn = () => (
    <>
      <div className={styles['buttons-container']}>
        <Button style={{minWidth: '6.5rem'}} onClick={() => setChatOpacity(chatOpacity ? 0 : 1)}>
          Toggle chat
        </Button>
        <IconButton icon='sign-out-alt' link={RoutePaths.logout} size={'2x'}>Logout</IconButton>
      </div>
      <div className={styles['chat']} style={{opacity: chatOpacity}}>
        <ChatBox
          currentUsername={'userId'}
          chatMessages={[]}
        />
      </div>
      </>
  );

  return (
    <Container className={styles['page-container-grid']} fluid={true}>
      <div className={styles['dead-space']}/>
      {renderHeaderAndContent()}
      {renderChatColumn()}
      <div className={styles['footer']}>
        <div className={styles['items']}>
          <LinkContainer to={RoutePaths.reportBug}>
            <Button>Report a bug</Button>
          </LinkContainer>
        </div>
      </div>
    </Container>
  );

}
