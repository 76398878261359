import React, {FormEvent, useEffect, useState} from 'react';
import {Button, Modal} from 'react-bootstrap';
import {Form} from 'react-bootstrap/es';
import {FormControlProps} from 'react-bootstrap/FormControl';
import {bindActionCreators, Dispatch} from 'redux';
import {holdemSocketStore} from '../../../../../common/redux/holdem/holdemSocket';
import {connect} from 'react-redux';

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

interface Props extends DispatchProps {
  tableId: string;
  currentChips: number;
  buyInAmount: number;
}

const BuyInModal = (props: Props) => {

  const {tableId, currentChips, buyInAmount, actions: {buyBackIn}} = props;
  const [show, setShow] = useState(false);
  const [currentBuyIn, setCurrentBuyIn] = useState('0');
  const [alreadyShown, setAlreadyShown] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClick = () => {
    if (currentBuyIn <= buyInAmount.toString()) {
      buyBackIn(tableId, currentBuyIn);
      handleClose();
    } else {
      setErrorMessage(`The maximum buy in is ${buyInAmount}`);
    }
  };

  useEffect(() => {
    if (!alreadyShown && currentChips === 0) {
      handleShow();
      setAlreadyShown(true);
    }
  }, [alreadyShown, currentChips]);

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header>Buy back into the game?</Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Control
            type={'number'}
            value={currentBuyIn.toString()}
            onChange={(event: FormEvent<FormControlProps>) => {
              // tslint:disable-next-line:max-line-length
              setCurrentBuyIn(event.currentTarget.value!);
            }}
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <div>{errorMessage}</div>
        <Button variant={'secondary'} onClick={handleClose}>Dismiss</Button>
        <Button variant={'primary'} onClick={() => handleClick()}>Buy Back In</Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    buyBackIn: holdemSocketStore.actions.buyBackIn
}, dispatch)});

export default connect(null, mapDispatchToProps)(BuyInModal);
