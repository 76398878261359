import {api} from './index';
import {Bank} from '../common/redux/entities/bank';

export async function getBanks() {
  return (await api.get<Bank[]>('/Bank')).data;
}

export async function getBank(id: string) {
  return (await api.get<Bank>(`/Bank/${id}`)).data;
}

export async function addBalanceToBank(id: string, amount: number) {
  return (await api.get<number>(`/Bank/add/${id}/${amount}`));
}

export async function removeBalanceFromBank(id: string, amount: number) {
  return (await api.get<number>(`/Bank/remove/${id}/${amount}`));
}

export default null;
